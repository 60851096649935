
      @import "variables/_colors.scss";
      @import "variables/_fontFamily.scss";
    
.header {
  color: black;
  width: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  z-index: 400;

  min-height: 64px;
  justify-content: center;
  background-color: $primary-100;
  transition: all 0.3s;
  box-shadow: 0px 2px 20px 0px $primary-1000;

  @media (max-width: 1024px) {
    min-height: unset;
  }

  &.hidden {
    top: -2rem;
  }

  &.visible {
    top: 0;
  }
}

.container {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  display: flex;

  align-items: center;
  justify-content: space-between;
}

.mobileAlert {
  display: flex;
  width: 100%;
  align-items: flex-end;
  border-bottom: 1px solid $primary-200;
  padding-bottom: 0.25rem;
  margin-bottom: 0.25rem;
}

.alertButton {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  margin-right: 16px;

  &:hover {
    background-color: rgba(220, 38, 38, 0.1);
  }
}

.alertIcon {
  font-size: 20px;
}

.alertText {
  color: rgb(220, 38, 38);
  font-weight: 500;
}

.dropdownImage {
  width: 400px;
  height: 100%;
  position: relative;
  overflow: hidden;

  .navigationImage {
    object-fit: cover;
    width: 100%;
    height: revert-layer;
  }
}

.logo {
  height: 32px;
  fill: $primary-1000 !important;
  transition: all 0.3s;
}

.logoContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.navigation {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-left: auto;
}

.navButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: $primary-1000;
  font-size: 16px;
  font-weight: 500;
  padding: 0.5rem;
  border: none;
  background: none;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: $primary-1000;
  }
}

.chevron {
  transition: transform 0.3s ease;
}

.rotateIcon {
  transform: rotate(180deg);
}

.dropdown {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  position: fixed;
  transition: all 0.5s;
  margin-top: -0.5rem;
  background-color: white;
  height: 24rem;
  z-index: 39;

  &.dropdownActive {
    top: 4rem;
    opacity: 1;
    visibility: visible;
  }

  &.dropdownHidden {
    top: -24rem;
    opacity: 0;
    visibility: hidden;
  }
}

.dropdownContent {
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 1.5rem;
}

.groupTitle {
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  color: $primary-900;
}

.groupList {
  color: $primary-400;
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.groupItem {
  color: $primary-800;
  font-size: 1rem;
  display: flex;
  align-items: center;
  transition: color 0.3s;

  &:hover {
    color: black;
  }
}

/* Mobile Menu Styles */
.mobileMenu {
  width: 100vw;
  height: calc(100vh - 4rem);
  transition: all 0.5s;
  padding-top: 2.5rem;
  position: fixed;
  z-index: 39;
  display: none;
  flex-direction: column;
  background-color: $primary-100;
  overflow-y: auto;

  &.mobileMenuActive {
    display: flex;
    transform: translateX(0);
  }

  &.mobileMenuHidden {
    transform: translateX(-100%);
  }
}

.mobileMenuItem {
  width: 100%;
  padding: 1rem;
  border-bottom: 1px solid $primary-200;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $primary-900;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: $primary-200;
  }
}

.mobileSubmenu {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.7s;
  height: fit-content;
  overflow-y: hidden;
  background-color: $primary-100;
}

.menuIcon {
  display: none;
  cursor: pointer;
  color: $primary-900;

  &:hover {
    color: $primary-1000;
  }
}

.actionsContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.consultorButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 8px 8px;
  color: $alpha-light;
  background-color: $primary-blue-600;
  cursor: pointer;
  border: none;
  transition: all 0.3s;
  font-weight: 500;

  &:hover {
    background-color: $primary-blue-700;
  }
}

.themeToggle {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.themeIcon {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: $primary-900;
    transition: color 0.3s;
  }
}

.themeInput {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;

  &:checked + .themeLabel {
    background: $primary-blue-600;

    &::after {
      left: calc(100% - 2px);
      transform: translateX(-100%);
    }
  }

  &:focus + .themeLabel {
    box-shadow: 0 0 0 2px $primary-blue-200;
  }
}

.themeLabel {
  cursor: pointer;
  width: 44px;
  height: 22px;
  background: $primary-300;
  display: block;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.3s;

  &::after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 18px;
    height: 18px;
    background: $primary-100;
    border-radius: 50%;
    transition: 0.3s;
  }
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 0;
  z-index: 0;
  display: none;
  opacity: 0;
  will-change: transform, opacity;
}

.categoriesContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  background: white;
  padding: 1.5rem;
}

.categoriesHeader {
  display: flex;
  justify-content: center;
  gap: 2rem;
  position: relative;
  padding-bottom: 1rem;
  margin-bottom: 2rem;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: #e5e7eb;
  }
}

.categoryButton {
  padding: 0.75rem 1rem;
  font-size: 1rem;
  border: none;
  background: transparent;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
  color: #6b7280;

  &::after {
    content: "";
    position: absolute;
    bottom: -1rem;
    left: 0;
    width: 100%;
    height: 3px;
    background: transparent;
    transition: all 0.3s ease;
    z-index: 1;
  }

  &:hover {
    color: #111827;
  }

  &.active {
    color: #111827;
    font-weight: 500;

    &::after {
      background: currentColor;
    }
  }
}

.category-passeio.active {
  color: #111827;
  &::after {
    background: #111827;
  }
  & + .modelsContainer {
    background: rgba(17, 24, 39, 0.03);
  }
}

.category-esportivo.active {
  color: #dc2626;
  &::after {
    background: #dc2626;
  }
  & + .modelsContainer {
    background: rgba(220, 38, 38, 0.03);
  }
}

.category-luxo.active {
  color: #2563eb;
  &::after {
    background: #2563eb;
  }
  & + .modelsContainer {
    background: rgba(37, 99, 235, 0.03);
  }
}

.modelsContainer {
  position: relative;
  width: 100%;
  border-radius: 1rem;
  padding: 1.5rem;
  transition: background-color 0.3s ease;
}

.modelsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
  opacity: 0;
  visibility: hidden;
  height: 0;
  transition: all 0.3s ease;

  &.visible {
    opacity: 1;
    visibility: visible;
    height: auto;
  }
}

.modelCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: inherit;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
}

.imageContainer {
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 0.5rem;
}

.modelImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.modelName {
  font-size: 0.9rem;
  text-align: center;
  font-weight: 500;
}

.externalIcon {
  margin-left: 0.5rem;
  font-size: 0.9rem;
}

.categoriesList {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

.categoryGroup {
  border: 1px solid $primary-200;
  border-radius: 0.5rem;
  overflow: hidden;
}

.modelsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  padding: 0;

  overflow: hidden;
  transition: all 0.3s ease;

  &.expanded {
    padding: 1rem;
    max-height: 1000px;
  }
}

.modelCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background-color: white;
  border-radius: 0.5rem;
  text-decoration: none;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
}

.imageContainer {
  width: 100%;
  height: 120px;
  position: relative;
  margin-bottom: 0.5rem;

  img {
    object-fit: contain;
  }
}

.modelName {
  color: $primary-900;
  font-weight: 500;
  text-align: center;
}

.externalIcon {
  margin-left: 0.5rem;
  font-size: 0.9rem;
}

.modelsGrid.expanded {
  padding: 1rem;
  max-height: 1000px;
  opacity: 1;
  visibility: visible;
}

.modelsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  padding: 0;

  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}

.imageContainer {
  width: 100%;
  height: 120px;
  position: relative;
  margin-bottom: 0.5rem;

  img {
    object-fit: contain;
  }
}

.modelName {
  color: $primary-900;
  font-weight: 500;
  text-align: center;
}

.externalIcon {
  margin-left: 0.5rem;
  font-size: 0.9rem;
}

.defaultDropdownContent {
  display: flex;
  position: relative;
  padding: 0;
  min-height: 300px;
}

.dropdownGroup {
  min-width: 200px;
  flex: 1;
  padding: 24px;
}

.groupTitle {
  margin-bottom: 1rem;
  font-size: 1.1rem;
  font-weight: 600;
  color: $primary-900;
}

.groupList {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.groupItem {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: $primary-700;
  text-decoration: none;
  font-size: 1rem;
  transition: all 0.3s;

  &:hover {
    color: $primary-900;
    padding-left: 0.5rem;
  }
}

.externalIcon {
  font-size: 0.9rem;
}
