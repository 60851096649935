$midnight-blue: #425160;
$slate-gray: #5c6875;
$charcoal: #313941;
$eerie-black: #202020;
$raisin-black: #181818;
$jet: #303030;
$jet-black: #131414;
$cadet-blue: #aeb1bd;
$lust-red: #e40521;
$amaranth: #e22e2e;
$salmon: #fa7e7e;
$salmon-20-opacity: #fa7e7e33;
$salmon-10-opacity: #fda8a811;
$silver-chalice: #afafaf;
$sonic-silver: #7c7c7c;
$granite-gray: #686d71;
$granite-gray-10-opacity: #686d7133;
$light-gray: #d3d3d3;
$white: #ffffff;
$isabelline: #efefef;
$cultured: #fafafa;
$onyx: #343434;
$onyx-80-opacity: #343434cc;
$sky-blue: #80c9ff;
$tufts-blue: #4f9edd;
$american-green: #23b33a;
$gray: #808080;
$tufts-blue-20-opacity: #4f9edd33;
$violet-blue: #3753b1;
$white-40-opacity: #ffffff66;
$black: #000000;
$black-25-opacity: #00000040;
$black-30-opacity: #0000004c;
$black-60-opacity: #00000099;
$eerie-black-25-opacity: #20202040;
$eerie-black-45-opacity: #20202072;
$eerie-black-50-opacity: #20202079;
$eerie-black-40-opacity: #20202066;
$white-80-opacity: #ffffffcc;
$marigold: #e9a635;
$whisper: #f1f1f1;
$granite-gray-12-opacity: #686d711f;
$sonic-silver-60-opacity: #7c7c7c99;
$tufts-blue-12-opacity: #4f9edd1f;
$transparent-white: #ffffff00;
$silver: #c4c4c4;

$moon-phase: #ffff;

$primary-100: #fcfcfd;
$primary-200: #eeeff0;
$primary-300: #dfe2e3;
$primary-400: #d0d5d6;
$primary-500: #b9c0c2;
$primary-600: #a9b0b2;
$primary-700: #838889;
$primary-800: #5c6061;
$primary-900: #353738;
$primary-1000: #0f0f10;

$primary-blue-100: #f6f8fc;
$primary-blue-200: #c7d3eb;
$primary-blue-300: #98aed9;
$primary-blue-400: #6a89c8;
$primary-blue-500: #3862b6;
$primary-blue-600: #1f4ead;
$primary-blue-700: #16377a;
$primary-blue-800: #0f2756;
$primary-blue-900: #091732;
$primary-blue-1000: #02060e;

// Neutral Colors
$neutral-100: #fcfcfd;
$neutral-200: #eeeff0;
$neutral-300: #dfe2e3;
$neutral-400: #d0d5d6;
$neutral-500: #b9c0c2;
$neutral-600: #a9b0b2;
$neutral-700: #838889;
$neutral-800: #5c6061;
$neutral-900: #353738;
$neutral-1000: #0f0f10;
$neutral-white: #ffffff;

// Semantic Colors
$success-100: #f2f9f7;
$success-200: #dcefea;
$success-300: #018254;
$success-400: #00301e;

$danger-100: #fff3f7;
$danger-200: #fad2de;
$danger-300: #e61046;
$danger-400: #711432;

$warning-100: #fffbea;
$warning-200: #f2cf56;
$warning-300: #fecc30;
$warning-400: #1a1100;

$info-100: #eaf1ff;
$info-200: #d0ddfb;
$info-300: #1f4497;
$info-400: #00081a;

$alpha-brand: #1f4ead;
$alpha-light: #ffffff;
$alpha-dark: #0a0a0b;
