@mixin heading-font-size($type, $size) {
  .#{$type}-large {
    font-size: $size;
    text-transform: uppercase;
  }

  .#{$type}-medium {
    font-size: $size - 24px;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    color: black;
  }

  .#{$type}-min {
    font-size: $size - 24px;
    text-transform: uppercase;
  }
}
