
      @import "variables/_colors.scss";
      @import "variables/_fontFamily.scss";
    
.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 48px;
  max-width: 1920px;
  margin: 0 auto;
  background-color: rgba(245 245 245);

  > nav > ul {
    display: flex;

    > li > a {
      padding: 8px 16px;
      color: $granite-gray;

      &.activeLink {
        background-color: $light-gray;
      }
    }
  }

  > button {
    color: black;

    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 12px;
  }
}
