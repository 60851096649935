
      @import "variables/_colors.scss";
      @import "variables/_fontFamily.scss";
    
// Container principal do Footer
.footerContainer {
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;
  background-color: #f2f2f2;

  @media (max-width: 768px) {
    padding: 0 1.25rem;
  }
}

// Estilos do Header
.header {
  display: flex;
  gap: 1.25rem;
  justify-content: space-between;
  padding: 1rem 0;
  width: 100%;
  border-bottom: 1px solid #d4d4d4;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    max-width: 100%;
  }

  .logoContainer {
    display: flex;

    .logoWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem 0;

      .logo {
        aspect-ratio: 5.88;
        width: 130px;
      }
    }

    .brandName {
      padding: 0 0.75rem;
      margin: auto 0;
      font-size: 1.25rem;
      white-space: nowrap;
      color: #666666;
    }
  }

  .socialNav {
    display: flex;
    gap: 1.25rem;
    justify-content: space-between;
    margin: auto 0;

    .socialIcon {
      flex-shrink: 0;
      aspect-ratio: 1;
      width: 25px;
    }
  }
}

// Estilos da Seção de Navegação
.navigationSection {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  padding: 2rem 1rem;
  max-width: 1140px;
  margin: 0 auto;
  width: 100%;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 1rem;
  }

  .section {
    .title {
      color: #000;
      font-weight: 600;
      margin-bottom: 1rem;
      font-size: 1rem;
    }

    .linksList {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .link {
        color: #666666;
        text-decoration: none;
        font-size: 0.875rem;
        transition: color 0.3s ease;

        &:hover {
          color: #000;
        }

        &.external {
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }
      }
    }
  }
}

// Estilos do Footer
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 4rem;

  @media (max-width: 768px) {
    padding: 1rem 1.25rem;
  }

  .container {
    display: flex;
    gap: 1.25rem;
    justify-content: space-between;
    width: 100%;
    max-width: 810px;

    @media (max-width: 768px) {
      flex-wrap: wrap;
    }

    .companyInfo {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      color: #000;

      .logo {
        width: 12rem;
        max-width: 100%;
        aspect-ratio: 4;
      }

      .info {
        align-self: stretch;
        padding: 0 1rem;
        margin-top: 1rem;
        font-size: 0.875rem;
        letter-spacing: -0.025em;
      }

      .copyright {
        margin-top: 0.5rem;
        font-size: 0.75rem;
        line-height: 1.25rem;
      }
    }

    .safety {
      display: flex;
      justify-content: center;
      margin: auto 0;
      font-size: 0.875rem;
      font-weight: 700;
      line-height: 1.5rem;
      color: #666666;

      .logo {
        flex-shrink: 0;
        aspect-ratio: 1;
        width: 60px;
      }

      .text {
        padding: 0.625rem 0.375rem;
        margin: auto 0;
      }
    }
  }
}

// Estilos do Accordion (Mobile)
.accordion {
  border-bottom: 1px solid #e5e7eb;

  .accordionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    width: 100%;
    background: none;
    border: none;
    cursor: pointer;

    .title {
      font-weight: 600;
      color: #000;
    }

    .icon {
      transition: transform 0.3s ease;

      &.active {
        transform: rotate(180deg);
      }
    }
  }

  .accordionContent {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;

    &.active {
      max-height: 500px;
    }

    .linksList {
      padding: 0 1rem 1rem;
    }
  }
}

// Estilos do Accordion Normal
.accordionNormal {
  width: 100%;

  .accordionHeader {
    display: flex;
    gap: 1.25rem;
    justify-content: space-between;
    padding: 0.5rem 0;
    width: 100%;
    cursor: pointer;
    background: none;
    border: none;

    .title {
      margin: auto 0;
    }

    .icon {
      transition: all 0.3s ease-in-out;

      &.active {
        transform: rotate(180deg);
      }
    }
  }

  .accordionContent {
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    height: 100%;
    transition: all 0.7s ease-in-out;
    overflow: hidden;
    max-height: 0;

    &.active {
      max-height: 800px;
    }

    .link {
      margin-top: 0.625rem;
      letter-spacing: normal;
      color: #666666;
      text-decoration: none;
      transition: color 0.3s ease;

      &:hover {
        color: #000;
      }
    }
  }
}
