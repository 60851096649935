.btn-primary {
  border: 2px solid white;
  color: $white;
  > .CMS {
    color: white;
  }
}

.btn-secondary {
  border: 2px solid white;
  color: $white;
}

.btn-primary,
.btn-secondary {
  padding: 12px 24px;
  background-color: $lust-red;

  &:hover {
    background-color: $eerie-black-25-opacity;
  }

  &.selected {
    color: $white;
    background-color: $eerie-black;
  }

  &:disabled {
    background-color: $light-gray;
    border-color: $light-gray;
    color: $silver-chalice;
    cursor: not-allowed;
  }
}

.btn-primary {
  &.min {
    padding: 8px;
  }

  &.black {
    color: $eerie-black;
  }

  &.fw600 {
    font-weight: 600;
  }
}

.btn-tertiary {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  width: 152px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  background-color: $lust-red;
  color: $white;
  line-height: 150%;
  border: 0.67px solid $white;
  backdrop-filter: blur(5px);

  @media (min-width: 540px) {
    width: 220px;
  }

  &:hover {
    background-color: $amaranth;
    color: $white;
  }
}

.btn-confirm {
  background-color: $violet-blue;
  display: flex;
  height: 48px;
  padding: 0px 56px;
  justify-content: center;
  align-items: center;
  color: $white;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &:disabled {
    background-color: $light-gray;
    border-color: $light-gray;
    color: $silver-chalice;
    cursor: not-allowed;
  }
}

.btn-confirm-wpp {
  background-color: $american-green;
  display: flex;
  height: 48px;
  padding: 0px 56px;
  justify-content: center;
  align-items: center;
  color: $white;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &:disabled {
    background-color: $light-gray;
    border-color: $light-gray;
    color: $silver-chalice;
    cursor: not-allowed;
  }
}

body.dark {
  .btn-primary {
    border-color: $white;

    &:hover {
      background-color: $white-40-opacity;
    }

    &.black {
      color: $white;
    }
  }
}
