
      @import "variables/_colors.scss";
      @import "variables/_fontFamily.scss";
    
.mobileContainer {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 400;
  background-color: var(--primary-100);
  box-shadow: 0px 2px 20px 0px var(--primary-1000);
}

.mobileHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: white;
}

.mobileActions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.menuButton {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--primary-900);
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.3s;

  &:hover {
    color: var(--primary-1000);
  }
}

.mobileDropdown {
  display: none;
  position: fixed;
  top: calc(80px);
  left: 0;
  right: 0;
  height: calc(100vh);
  background-color: $primary-100;
  padding: 1rem;
  flex-direction: column;
  z-index: 1000;
  overflow-y: auto;
}

.mobileNav {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.mobileNavLink {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  color: var(--primary-900);
  text-decoration: none;
  font-size: 1.1rem;
  border-bottom: 1px solid var(--primary-200);
  transition: all 0.3s;

  &:hover {
    background-color: var(--primary-200);
    padding-left: 1.5rem;
  }
}

.themeToggle {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.themeIcon {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: var(--primary-900);
    transition: color 0.3s;
  }
}

.themeInput {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;

  &:checked + .themeLabel {
    background: var(--primary-blue-600);

    &::after {
      left: calc(100% - 2px);
      transform: translateX(-100%);
    }
  }

  &:focus + .themeLabel {
    box-shadow: 0 0 0 2px var(--primary-blue-200);
  }
}

.themeLabel {
  cursor: pointer;
  width: 44px;
  height: 22px;
  background: var(--primary-300);
  display: block;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.3s;

  &::after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 18px;
    height: 18px;
    background: var(--primary-100);
    border-radius: 50%;
    transition: 0.3s;
  }
}

.logoContainer {
  svg {
    height: 32px;
    fill: var(--primary-1000) !important;
    transition: all 0.3s;
  }
}

.navSection {
  width: 100%;
  border-bottom: 1px solid var(--primary-200);
}

.sectionButton {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: none;
  border: none;
  color: var(--primary-900);
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: var(--primary-50);
  }

  &.active {
    background-color: var(--primary-100);
  }
}

.chevron {
  transition: transform 0.3s ease;

  &.rotated {
    transform: rotate(180deg);
  }
}

.sectionContent {
  padding: 0.5rem 0;
  background-color: var(--primary-50);
}

.groupContainer {
  padding: 0.5rem 1rem;
}

.groupTitle {
  font-size: 1rem;
  font-weight: 600;
  color: var(--primary-800);
  margin: 0.5rem 0;
  padding-left: 0.5rem;
}

.groupItems {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &.gridItems {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
}

.itemLink {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  color: var(--primary-700);
  text-decoration: none;
  font-size: 0.95rem;
  border-radius: 0.25rem;
  transition: all 0.2s;

  &:hover {
    background-color: var(--primary-100);
    padding-left: 1.5rem;
  }

  &.gridItemLink {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0.5rem;
    gap: 0.5rem;
    background-color: var(--primary-50);
    border: 1px solid var(--primary-200);

    &:hover {
      background-color: var(--primary-100);
      padding-left: 0.5rem;
      transform: translateY(-2px);
    }
  }
}

.imageContainer {
  width: 100%;
  height: 80px;
  position: relative;
  overflow: hidden;
  border-radius: 0.25rem;
  background-color: var(--primary-100);
}

.modelImage {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.itemName {
  font-weight: 500;
  color: var(--primary-900);
}

.alertHeader {
  width: 100%;
  background-color: $primary-100;
  border-bottom: 1px solid $primary-200;
}

.alertButton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.25rem;
  color: rgb(220, 38, 38);
  font-weight: 500;
  transition: background-color 0.3s;
  background-color: $primary-100;

  &:hover {
    background-color: rgba(220, 38, 38, 0.1);
  }
}

.alertIcon {
  font-size: 1rem;
}

.alertText {
  font-size: 0.75rem;
  animation: pulse 2s infinite;
}
